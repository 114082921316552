/*
 * Variables
 */


/*------------------------------------*\
               # Colors
\*------------------------------------*/
$black: #101010;
$gray: #231f20;
$lightGray: lighten($gray, 50);
$veryLightGray: lighten($gray, 80);
$darkGray: #110F10;
$green: #00783f;
$darkGreen: darken($green, 5);
$lightGreen: lighten($green, 5);
$red: #e20011;
$accentColor: $green;
$border-radius: 15px;

$shoolu: #02a974;
$tan: #dfca8a;


:root{

    --black: #{$black};
    --gray: #{$gray};
    --lightGray: #{$lightGray};
    --veryLightGray: #{$veryLightGray};
    --darkGray: #{$darkGray};
    --green: #{$green};
    --darkGreen: #{$darkGreen};
    --lightGreen: #{$lightGreen};
    --red: #{$red};
    --accentColor: #{$accentColor};

    --borderRadius: #{$border-radius};

}






/*------------------------------------*\
    # Simple Typography Settings
\*------------------------------------*/

// Font Family
$font-family: 'Usual',
sans-serif;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: #505050;
$paragraph-size: rem(17px);
$paragraph-line-height: 1.5em;
$paragraph-margin: 20px;

// Header Styles
$header-color: $gray;
$header-size: rem(40px);
$header-line-height: 1.25em;
$header-margin: $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color: #505050;
$blockquote-size: rem(23px);
$blockquote-line-height: 1.5em;
$blockquote-margin: $paragraph-margin * 1.5;

// Links
$link-color: $accentColor;

// hr
$hr-margin: 1rem;

// Type Misc
$font-weight-bold: 800;
$border-color: #DADADA;
$hr-color: $gray;