/*!
 * Theme Name: Shoebox
 * Theme URI: git+https://github.com/harnerdesigns/mighty-tree.git
 * GitHub Theme URI: git+https://github.com/harnerdesigns/mighty-tree.git
 * Description: Custom WordPress Theme for The Shoe Box.
 * Version: 1.0.0
 * Author: 
 * Author URI: 
 * License: ISC
 *  *//* line 1, assets/scss/_reset.scss */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 94, assets/scss/_reset.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

/* line 108, assets/scss/_reset.scss */
body {
  line-height: 1; }

/* line 112, assets/scss/_reset.scss */
ol,
ul {
  list-style: none; }

/* line 117, assets/scss/_reset.scss */
blockquote,
q {
  quotes: none; }

/* line 123, assets/scss/_reset.scss */
blockquote:before, blockquote:after {
  content: '';
  content: none; }

/* line 131, assets/scss/_reset.scss */
q:before, q:after {
  content: '';
  content: none; }

/* line 138, assets/scss/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 143, assets/scss/_reset.scss */
* {
  box-sizing: border-box; }

/* line 147, assets/scss/_reset.scss */
.wp-block-image {
  margin: 0; }

/* line 46, assets/scss/_mixins.scss */
.mobile-only {
  display: none; }
  @media (max-width: 768px) {
    /* line 46, assets/scss/_mixins.scss */
    .mobile-only {
      display: flex; } }

/* line 53, assets/scss/_mixins.scss */
.tablet-only {
  display: none; }
  @media (max-width: 768px) {
    /* line 53, assets/scss/_mixins.scss */
    .tablet-only {
      display: flex; } }
  @media (min-width: 768px) {
    /* line 53, assets/scss/_mixins.scss */
    .tablet-only {
      display: flex; } }
  @media (min-width: 1024px) {
    /* line 53, assets/scss/_mixins.scss */
    .tablet-only {
      display: none; } }

/* line 65, assets/scss/_mixins.scss */
.desktop-only {
  display: none; }
  @media (min-width: 1024px) {
    /* line 65, assets/scss/_mixins.scss */
    .desktop-only {
      display: flex; } }

/*
 * Variables
 */
/*------------------------------------*\
               # Colors
\*------------------------------------*/
/* line 25, assets/scss/_vars.scss */
:root {
  --black: #101010;
  --gray: #231f20;
  --lightGray: #a69b9e;
  --veryLightGray: #eeecec;
  --darkGray: #110F10;
  --green: #00783f;
  --darkGreen: #005f32;
  --lightGreen: #00924c;
  --red: #e20011;
  --accentColor: #00783f;
  --borderRadius: 15px; }

/*------------------------------------*\
    # Simple Typography Settings
\*------------------------------------*/
/* line 1, assets/scss/_layout.scss */
.grid {
  display: grid;
  position: relative; }

/* line 7, assets/scss/_layout.scss */
.grid--1 {
  grid-template-columns: repeat(1, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-1 {
  grid-column: span 1; }

/* line 7, assets/scss/_layout.scss */
.grid--2 {
  grid-template-columns: repeat(2, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-2 {
  grid-column: span 2; }

/* line 7, assets/scss/_layout.scss */
.grid--3 {
  grid-template-columns: repeat(3, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-3 {
  grid-column: span 3; }

/* line 7, assets/scss/_layout.scss */
.grid--4 {
  grid-template-columns: repeat(4, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-4 {
  grid-column: span 4; }

/* line 7, assets/scss/_layout.scss */
.grid--5 {
  grid-template-columns: repeat(5, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-5 {
  grid-column: span 5; }

/* line 7, assets/scss/_layout.scss */
.grid--6 {
  grid-template-columns: repeat(6, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-6 {
  grid-column: span 6; }

/* line 7, assets/scss/_layout.scss */
.grid--7 {
  grid-template-columns: repeat(7, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-7 {
  grid-column: span 7; }

/* line 7, assets/scss/_layout.scss */
.grid--8 {
  grid-template-columns: repeat(8, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-8 {
  grid-column: span 8; }

/* line 7, assets/scss/_layout.scss */
.grid--9 {
  grid-template-columns: repeat(9, 1fr); }

/* line 10, assets/scss/_layout.scss */
.grid--span-9 {
  grid-column: span 9; }

/* line 14, assets/scss/_layout.scss */
.grid--span-all {
  grid-column: 1 / -1; }

@media (max-width: 600) {
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-1 {
    grid-template-columns: repeat(1, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-1 {
    grid-column: span 1; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-2 {
    grid-template-columns: repeat(2, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-2 {
    grid-column: span 2; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-3 {
    grid-template-columns: repeat(3, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-3 {
    grid-column: span 3; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-4 {
    grid-template-columns: repeat(4, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-4 {
    grid-column: span 4; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-5 {
    grid-template-columns: repeat(5, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-5 {
    grid-column: span 5; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-6 {
    grid-template-columns: repeat(6, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-6 {
    grid-column: span 6; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-7 {
    grid-template-columns: repeat(7, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-7 {
    grid-column: span 7; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-8 {
    grid-template-columns: repeat(8, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-8 {
    grid-column: span 8; }
  /* line 20, assets/scss/_layout.scss */
  .grid--mobile-9 {
    grid-template-columns: repeat(9, 1fr); }
  /* line 23, assets/scss/_layout.scss */
  .grid--mobile-span-9 {
    grid-column: span 9; } }

@media (max-width: 768px) {
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-1 {
    grid-template-columns: repeat(1, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-1 {
    grid-column: span 1; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-2 {
    grid-template-columns: repeat(2, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-2 {
    grid-column: span 2; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-3 {
    grid-template-columns: repeat(3, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-3 {
    grid-column: span 3; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-4 {
    grid-template-columns: repeat(4, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-4 {
    grid-column: span 4; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-5 {
    grid-template-columns: repeat(5, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-5 {
    grid-column: span 5; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-6 {
    grid-template-columns: repeat(6, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-6 {
    grid-column: span 6; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-7 {
    grid-template-columns: repeat(7, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-7 {
    grid-column: span 7; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-8 {
    grid-template-columns: repeat(8, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-8 {
    grid-column: span 8; }
  /* line 31, assets/scss/_layout.scss */
  .grid--tablet-9 {
    grid-template-columns: repeat(9, 1fr); }
  /* line 34, assets/scss/_layout.scss */
  .grid--tablet-span-9 {
    grid-column: span 9; } }

/* line 39, assets/scss/_layout.scss */
.grid--mobile-span-all {
  grid-column: 1 / -1; }

/* line 44, assets/scss/_layout.scss */
main {
  position: relative; }

@use "sass:math";
/*------------------------------------*\
    # Type
\*------------------------------------*/
/* line 7, assets/scss/_type.scss */
*,
html,
body {
  font-size: 16px;
  font-family: "Usual", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/* line 20, assets/scss/_type.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 36px 0 24px 0;
  color: #231f20;
  line-height: 1.25em;
  font-weight: 900; }

/* line 32, assets/scss/_type.scss */
h1 {
  font-size: 2.5rem; }

/* line 36, assets/scss/_type.scss */
h2 {
  font-size: calc(2.5rem / 1.3); }

/* line 40, assets/scss/_type.scss */
h3 {
  font-size: calc(2.5rem / 1.5); }

/* line 44, assets/scss/_type.scss */
h4 {
  font-size: calc(2.5rem / 1.7); }

/* line 48, assets/scss/_type.scss */
h5 {
  font-size: calc(2.5rem / 1.8); }

/* line 52, assets/scss/_type.scss */
h6 {
  font-size: calc(2.5rem / 2); }

/* line 59, assets/scss/_type.scss */
p {
  margin-top: 20px;
  font-size: 1.0625rem;
  line-height: 1.5em;
  color: #505050; }
  /* line 65, assets/scss/_type.scss */
  p:first-of-type {
    margin-top: 0; }

/* line 73, assets/scss/_type.scss */
a {
  color: #00783f;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  /* line 78, assets/scss/_type.scss */
  a:hover {
    color: #004524; }

/* line 85, assets/scss/_type.scss */
ul,
ol {
  margin: 20px 0 0 20px; }
  /* line 89, assets/scss/_type.scss */
  ul li,
  ol li {
    margin-top: 10px;
    line-height: 1.5em;
    color: #505050; }
  /* line 95, assets/scss/_type.scss */
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0; }

/* line 103, assets/scss/_type.scss */
ul li {
  list-style: disc; }

/* line 109, assets/scss/_type.scss */
ul ul li {
  list-style: circle; }

/* line 117, assets/scss/_type.scss */
ol li {
  list-style: decimal; }

/* line 124, assets/scss/_type.scss */
hr {
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  border: none;
  background-color: #DADADA;
  display: block;
  position: relative; }

/* line 136, assets/scss/_type.scss */
blockquote {
  margin: 30px 0;
  font-size: 1.4375rem;
  line-height: 1.5em;
  color: #505050;
  text-align: center;
  font-style: italic; }

/* line 147, assets/scss/_type.scss */
table {
  width: 100%;
  margin: 30px 0;
  border-collapse: collapse; }

/* line 153, assets/scss/_type.scss */
table,
td,
th {
  border: 1px solid #DADADA;
  text-align: left; }

/* line 160, assets/scss/_type.scss */
th {
  font-weight: 800; }

/* line 164, assets/scss/_type.scss */
th,
td {
  padding: 15px; }

/* line 172, assets/scss/_type.scss */
figcaption {
  margin-top: 10px;
  color: #6a6a6a;
  font-size: 16px; }

/* line 180, assets/scss/_type.scss */
pre {
  display: block;
  margin: 0 0 40px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #DADADA;
  overflow: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  /* line 191, assets/scss/_type.scss */
  pre code {
    position: static;
    padding: 0;
    border: none;
    line-height: 1.5em; }

/* line 199, assets/scss/_type.scss */
code {
  position: relative;
  top: -0.2em;
  padding: 3px;
  font-family: Courier New, Courier, monospace;
  font-size: calc($paragraph-size / 1.4);
  color: #747474;
  line-height: 1em;
  pointer-events: none;
  border: 1px solid #DADADA;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

/* line 217, assets/scss/_type.scss */
em,
i,
.italic {
  font-style: italic; }

/* line 223, assets/scss/_type.scss */
strong,
b,
.bold {
  font-weight: 800; }

/* line 229, assets/scss/_type.scss */
img {
  display: block;
  max-width: 100%; }

/*header*/
/* line 3, assets/scss/components/_header.scss */
header {
  width: 100%;
  background: #dfca8a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  height: 10vh;
  filter: drop-shadow(0px 0px 10px #000);
  font-weight: 400;
  z-index: 9999; }

/* line 20, assets/scss/components/_header.scss */
.logo {
  height: 15vh;
  border-radius: 0 0 90% 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfca8a;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 35%; }

/* line 34, assets/scss/components/_header.scss */
.logo a {
  width: 70%; }

/* line 38, assets/scss/components/_header.scss */
.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

/* line 44, assets/scss/components/_header.scss */
.headerPromo {
  width: 100%;
  background: #e20011;
  color: #fff;
  padding: 0.5em 0;
  text-align: center;
  font-size: 1.5rem;
  display: block; }

/* line 53, assets/scss/components/_header.scss */
.headerPromo a {
  color: #fff;
  border: 2px solid;
  padding: 0.1rem 0.5rem;
  transition: all 300ms;
  border-radius: 3px;
  text-transform: uppercase;
  margin-left: 1rem;
  font-weight: 900; }

/* line 63, assets/scss/components/_header.scss */
.headerPromo a:hover {
  background: #fff;
  color: #e20011;
  text-decoration: none;
  border: 2px solid #fff; }

/* line 70, assets/scss/components/_header.scss */
.menuRight,
.menuLeft {
  width: 20%;
  font-size: 1.25rem; }

/* line 75, assets/scss/components/_header.scss */
nav.mobile {
  display: none; }

/* line 78, assets/scss/components/_header.scss */
.mobileNavButton {
  display: none; }

/* line 81, assets/scss/components/_header.scss */
.mobilespacer {
  display: none; }

/* line 84, assets/scss/components/_header.scss */
.mobileNavExit {
  display: none; }

/* line 87, assets/scss/components/_header.scss */
.menuRight ul,
.menuLeft ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

/* line 103, assets/scss/components/_header.scss */
.menuRight li,
.menuLeft li {
  display: inline-block; }

/* line 108, assets/scss/components/_header.scss */
nav li a {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold; }

@media (max-width: 768px) {
  /* line 117, assets/scss/components/_header.scss */
  nav.mobile {
    position: fixed;
    top: 0;
    left: -100vw;
    z-index: 999;
    transition: left 200ms;
    display: block; }
  /* line 125, assets/scss/components/_header.scss */
  .mobileNavExit {
    display: none; }
  /* line 128, assets/scss/components/_header.scss */
  #nav:target {
    left: 0; }
    /* line 130, assets/scss/components/_header.scss */
    #nav:target .mobileNavExit {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 998;
      display: flex; }
  /* line 143, assets/scss/components/_header.scss */
  .mobileNavButton {
    display: block;
    font-size: 2rem; }
  /* line 147, assets/scss/components/_header.scss */
  .mobilespacer {
    display: block; }
  /* line 150, assets/scss/components/_header.scss */
  .mobile ul {
    min-width: 70vw;
    background: #333;
    margin: 0;
    padding: 0;
    height: 100vh;
    box-shadow: 0 2px 3px #000;
    z-index: 999;
    position: relative; }
  /* line 160, assets/scss/components/_header.scss */
  .mobile li {
    display: block;
    color: #fff;
    padding: 1.5rem 1rem;
    font-size: 3rem;
    border-bottom: 1px solid #fff;
    margin-top: 0; }
  /* line 169, assets/scss/components/_header.scss */
  .mobile li:hover {
    background: #777; }
  /* line 173, assets/scss/components/_header.scss */
  .mobile a {
    text-decoration: none;
    margin-top: 0; } }

/* line 8, assets/scss/style.scss */
* {
  box-sizing: border-box; }

/* line 12, assets/scss/style.scss */
#mobileTest {
  display: none; }

@media (min-width: 801px) {
  /* line 16, assets/scss/style.scss */
  #mobileTest {
    display: inherit; } }

/* line 20, assets/scss/style.scss */
body {
  background: #ffffff;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 100; }

/* line 28, assets/scss/style.scss */
.clear {
  float: none;
  clear: both;
  display: block; }

/* line 34, assets/scss/style.scss */
h1 {
  text-align: center;
  text-transform: uppercase;
  margin-top: 0; }

/* line 39, assets/scss/style.scss */
h2 {
  color: #e20011;
  margin: 0.5em 0 0; }

/* line 44, assets/scss/style.scss */
p {
  font-size: 2rem;
  line-height: 1.5em;
  margin: 0.5em 0 0; }

/* line 49, assets/scss/style.scss */
a {
  color: #e20011;
  text-decoration: none; }

/* line 53, assets/scss/style.scss */
a:hover {
  text-decoration: underline; }

/* line 56, assets/scss/style.scss */
div.buttons {
  display: grid;
  grid-gap: 1rem;
  width: 100%;
  position: relative;
  grid-gap: 4rem; }
  @media (min-width: 1024px) {
    /* line 56, assets/scss/style.scss */
    div.buttons {
      grid-template-columns: 1fr 1fr;
      max-width: 1080px; } }

/* line 68, assets/scss/style.scss */
a.buttonLink {
  font-size: 1em;
  color: #fff !important;
  text-shadow: none;
  background: #e20011;
  width: 100%;
  padding: 0.25em;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1px 8px rgba(0, 0, 0, 0.12);
  transition: all 300ms;
  margin: 0.5em 0;
  display: block;
  text-align: center; }
  /* line 83, assets/scss/style.scss */
  a.buttonLink.shoolu {
    background: #02a974; }
    /* line 85, assets/scss/style.scss */
    a.buttonLink.shoolu:hover {
      background: #029063; }

/* line 90, assets/scss/style.scss */
a.buttonLink:hover {
  text-decoration: none;
  background: #ef5350;
  transform: scale(1.01);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  transition: all 100ms; }

/* line 97, assets/scss/style.scss */
a.buttonLink:active {
  text-decoration: none;
  background: #d32f2f;
  transform: scale(0.999);
  box-shadow: none; }

/* line 104, assets/scss/style.scss */
section .buttonLink {
  font-size: 1em; }

/* line 108, assets/scss/style.scss */
.error {
  width: 80%;
  margin: 1em auto;
  background: #ff9800;
  color: #fff;
  padding: 0.5em;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  text-align: center; }

/* line 121, assets/scss/style.scss */
.error:before {
  content: "\f071";
  font-family: fontAwesome;
  margin-right: 0.5em;
  font-weight: initial;
  font-size: 2rem;
  vertical-align: middle; }

/* line 129, assets/scss/style.scss */
.success {
  width: 80%;
  margin: 1em auto;
  background: #0fb681;
  color: #fff;
  padding: 0.5em;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  text-align: center; }

/* line 142, assets/scss/style.scss */
.success:before {
  content: "\f00c";
  font-family: fontAwesome;
  margin-right: 0.5em;
  font-weight: initial;
  font-size: 2rem;
  vertical-align: middle; }

/*---OR Separator---*/
/* line 153, assets/scss/style.scss */
hr.or {
  width: 100%;
  position: relative;
  border: none;
  font-size: 1.5rem;
  background: none; }
  /* line 159, assets/scss/style.scss */
  hr.or:after {
    position: absolute;
    content: "Or";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2em !important;
    height: 2em !important;
    border-radius: 2em;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }

/* line 177, assets/scss/style.scss */
.buttons .or {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 4;
  height: 2em;
  margin: 0; }

/*---BANNER---*/
/* line 190, assets/scss/style.scss */
.banner {
  width: 100%;
  background: url("assets/banner.jpg") center center fixed;
  background-size: cover;
  display: flex;
  padding: 2rem;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  height: 75vh;
  text-align: center;
  font-weight: bold;
  font-family: sans-serif;
  color: #fff;
  flex-direction: column; }
  /* line 207, assets/scss/style.scss */
  .banner.small {
    height: 40vh;
    box-shadow: inset 0 0 20px #000; }
  /* line 212, assets/scss/style.scss */
  .banner .logo-img {
    width: 2em;
    height: 2em;
    object-fit: contain;
    vertical-align: middle;
    background: #fff;
    padding: 0.25rem;
    border-radius: 15px; }
    @media (min-width: 1024px) {
      /* line 212, assets/scss/style.scss */
      .banner .logo-img {
        margin-right: 1rem; } }
  /* line 224, assets/scss/style.scss */
  .banner .buttonLink {
    font-size: 2rem; }
  /* line 228, assets/scss/style.scss */
  .banner h1,
  .banner h2 {
    margin: 0 auto;
    border-radius: 15px;
    color: #000;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center; }
  /* line 239, assets/scss/style.scss */
  .banner h1 {
    z-index: 2;
    width: 100%;
    font-size: 3rem;
    background: #dfca8a;
    color: #fff;
    padding: 1rem 2rem;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3); }
    @media (min-width: 1024px) {
      /* line 239, assets/scss/style.scss */
      .banner h1 {
        font-size: 6rem; } }
  /* line 253, assets/scss/style.scss */
  .banner h2 {
    background: #fff;
    border-radius: 0 0 15px 15px;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    font-size: 1rem;
    width: 80%; }
    @media (min-width: 1024px) {
      /* line 253, assets/scss/style.scss */
      .banner h2 {
        font-size: unset;
        width: auto; } }

/*--SLIDER---*/
/* line 269, assets/scss/style.scss */
.slider button {
  position: absolute;
  z-index: 9999;
  height: 100%;
  top: 0;
  width: 4vw;
  background: none;
  border: none;
  color: #fff;
  font-size: 3em;
  opacity: 0.6;
  transition: all 300ms; }

/* line 282, assets/scss/style.scss */
.slider button:hover {
  opacity: 1;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer; }

/* line 287, assets/scss/style.scss */
.slider button:focus {
  outline: none; }

/* line 291, assets/scss/style.scss */
.slick-prev {
  left: -4vw; }

/* line 294, assets/scss/style.scss */
.slick-next {
  right: -4vw; }

/* line 298, assets/scss/style.scss */
.slick-list {
  padding: 4px !important; }

/*---CONTENT---*/
/* line 304, assets/scss/style.scss */
section {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 1rem; }
  @media (min-width: 1024px) {
    /* line 304, assets/scss/style.scss */
    section {
      padding: 5vh 10vw; } }

/* line 314, assets/scss/style.scss */
iframe#map {
  border: 15px solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
  max-width: 100%;
  margin: 0 auto;
  height: 50vh;
  pointer-events: none; }

/* line 322, assets/scss/style.scss */
.maps {
  width: 100%; }

/* line 325, assets/scss/style.scss */
p img {
  border: 15px solid #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
  max-width: 40%; }

/* line 330, assets/scss/style.scss */
img.poster {
  max-width: 50vw;
  width: 100%;
  border: none;
  box-shadow: none;
  margin-top: -15vh; }

/* line 337, assets/scss/style.scss */
img.left {
  float: left;
  margin-right: 1vw; }

/* line 341, assets/scss/style.scss */
img.right {
  float: right;
  margin-left: 1vw; }

/* line 346, assets/scss/style.scss */
.dark {
  background: #d1bb84;
  box-shadow: inset 0 0 15px -2.5px #000; }

/* line 351, assets/scss/style.scss */
.headline {
  font-size: 3rem;
  text-align: center;
  line-height: 2em;
  margin: 5vh 0;
  font-weight: 100; }

/* line 359, assets/scss/style.scss */
.promo {
  width: 50%;
  margin: 0 auto;
  background: #e20011;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 2rem;
  padding: 1.5em;
  font-weight: 600;
  box-shadow: 0px 0px 10px -2.5px #000; }

/* line 384, assets/scss/style.scss */
.finePrint {
  font-size: 1rem;
  font-weight: 100; }

/* line 389, assets/scss/style.scss */
.featured {
  padding: 4rem 1rem; }
  /* line 391, assets/scss/style.scss */
  .featured h2 {
    font-size: 4rem;
    text-align: center;
    color: #000;
    margin: 1rem auto; }
  /* line 397, assets/scss/style.scss */
  .featured a {
    font-size: 2rem;
    text-align: right;
    display: block;
    max-width: 80%;
    margin: 0 auto; }
  /* line 405, assets/scss/style.scss */
  .featured .slider {
    max-width: 100%;
    margin: 1rem auto; }
    @media (min-width: 1024px) {
      /* line 405, assets/scss/style.scss */
      .featured .slider {
        max-width: 80%; } }

/* line 413, assets/scss/style.scss */
.grid {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

/* line 432, assets/scss/style.scss */
.item {
  flex: 1 1 auto;
  width: 23%;
  min-height: 20vw;
  font-size: 2rem;
  margin-bottom: 4vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  transition: all 300ms;
  padding: 1rem; }
  @media (min-width: 1024px) {
    /* line 432, assets/scss/style.scss */
    .item {
      padding: 4vh; } }

/* line 457, assets/scss/style.scss */
.slider .item {
  margin-bottom: 0;
  margin: 0 0.25em; }
  @media (min-width: 1024px) {
    /* line 457, assets/scss/style.scss */
    .slider .item {
      margin: 0 1em; } }

/* line 464, assets/scss/style.scss */
.item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -webkit-transition: all 300ms;
  transition: all 300ms; }

/* line 471, assets/scss/style.scss */
.brand {
  width: 15%;
  padding: 10px;
  border-radius: 20%; }

/* line 476, assets/scss/style.scss */
.button {
  min-height: 20vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

/* line 482, assets/scss/style.scss */
.button:hover {
  background: #e20011;
  color: #fff; }

/* line 486, assets/scss/style.scss */
.button:hover img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1); }

/* line 490, assets/scss/style.scss */
.button .fa {
  font-size: 8rem; }

/* line 493, assets/scss/style.scss */
.item a {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none; }

/* line 510, assets/scss/style.scss */
.item .content a {
  display: inline;
  font-weight: 900; }

/*--SURVEY--*/
/* line 516, assets/scss/style.scss */
.surveyForm input {
  width: 100%;
  font-size: 2rem;
  margin-bottom: 0.5em; }

/* line 521, assets/scss/style.scss */
.surveyForm input[type="text"],
.surveyForm input[type="email"] {
  border: none;
  border-bottom: 2px solid #000; }

/* line 526, assets/scss/style.scss */
.surveyForm input[type="submit"] {
  background: #e20011;
  color: #fff;
  border: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
  cursor: pointer;
  padding: 0.5em 0; }

/* line 534, assets/scss/style.scss */
.surveyForm label {
  font-size: 2rem; }

/* line 537, assets/scss/style.scss */
.surveyForm input[type="checkbox"] {
  width: auto;
  display: inline-block; }

/* line 541, assets/scss/style.scss */
.rating {
  margin: 0;
  font-size: 2rem;
  padding: 0;
  float: left; }

/* line 547, assets/scss/style.scss */
.rating li {
  display: inline-block; }

/* line 550, assets/scss/style.scss */
.rating input[type="radio"] {
  display: none; }

/* line 553, assets/scss/style.scss */
label.star {
  float: right;
  padding: 10px;
  font-size: 36px;
  color: #444;
  transition: all 0.2s; }

/* line 561, assets/scss/style.scss */
input.star:checked ~ label.star:before {
  content: "\f005";
  color: #fd4;
  transition: all 0.25s; }

/* line 567, assets/scss/style.scss */
input.star-5:checked ~ label.star:before {
  color: #fe7;
  text-shadow: 0 2px 3px #f9a825; }

/* line 572, assets/scss/style.scss */
input.star-1:checked ~ label.star:before {
  color: #f62; }

/* line 576, assets/scss/style.scss */
label.star:hover {
  transform: rotate(-15deg) scale(1.3); }

/* line 580, assets/scss/style.scss */
label.star:before {
  content: "\f006";
  font-family: FontAwesome; }

/*---FOOTER---*/
/* line 3, assets/scss/components/_footer.scss */
.hoursStuff {
  width: 100%;
  min-height: 30vh;
  background: #eee;
  background: url("assets/swirly-thing.png") center bottom/cover;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  margin-top: 5vh;
  padding: 1rem; }
  /* line 13, assets/scss/components/_footer.scss */
  .hoursStuff .card {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
    width: 100%;
    background: #fff;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1px 8px rgba(0, 0, 0, 0.12);
    text-align: center; }
    /* line 25, assets/scss/components/_footer.scss */
    .hoursStuff .card.shoolu img {
      margin: 1rem auto; }
    @media (max-width: 768px) {
      /* line 24, assets/scss/components/_footer.scss */
      .hoursStuff .card.shoolu {
        order: -1; } }
  /* line 34, assets/scss/components/_footer.scss */
  .hoursStuff .buttonLink {
    font-size: 1.5em;
    width: 100%;
    margin: 0.5em 0; }

/* line 42, assets/scss/components/_footer.scss */
.hoursStuff p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase; }

/* line 49, assets/scss/components/_footer.scss */
footer {
  padding: 3vh 10vw;
  background: #665a42;
  color: #fff;
  box-shadow: inset 0 1.5px 4px rgba(0, 0, 0, 0.24), inset 0 1px 8px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem; }
  /* line 65, assets/scss/components/_footer.scss */
  footer .fa {
    margin-left: 0.5em; }
  /* line 68, assets/scss/components/_footer.scss */
  footer a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: color 200ms;
    transition: color 200ms; }
  /* line 74, assets/scss/components/_footer.scss */
  footer a:hover {
    color: rgba(0, 0, 0, 0.6); }

@media screen and (max-width: 850px) {
  /* line 591, assets/scss/style.scss */
  html {
    font-size: 13px; }
  /* line 594, assets/scss/style.scss */
  section {
    padding: 5vh 1rem; }
  /* line 597, assets/scss/style.scss */
  .headline {
    margin-top: 0;
    line-height: 1.5em;
    font-size: 2rem; }
  /* line 602, assets/scss/style.scss */
  .promo {
    width: 100%; }
  /* line 605, assets/scss/style.scss */
  .item {
    width: 40%; }
  /* line 608, assets/scss/style.scss */
  .brand {
    width: 45%; }
  /* line 611, assets/scss/style.scss */
  .menuRight,
  .menuLeft {
    display: none; }
  /* line 615, assets/scss/style.scss */
  .logo {
    width: 60%; }
  /* line 618, assets/scss/style.scss */
  .buttonLink {
    width: 100% !important; }
  /* line 621, assets/scss/style.scss */
  .card {
    width: 70%; }
  /* line 624, assets/scss/style.scss */
  .hoursStuff {
    flex-direction: column;
    align-items: center; }
  /* line 630, assets/scss/style.scss */
  p img {
    max-width: 100%;
    width: 100%;
    height: auto; }
  /* line 635, assets/scss/style.scss */
  img.left,
  img.right {
    float: none;
    margin: 0; } }

/* line 642, assets/scss/style.scss */
.sqft-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0.5rem; }
  @media (min-width: 1024px) {
    /* line 642, assets/scss/style.scss */
    .sqft-banner {
      padding: 4rem 2rem; } }
  /* line 653, assets/scss/style.scss */
  .sqft-banner h1,
  .sqft-banner h2,
  .sqft-banner h3 {
    color: #000;
    font-weight: 900;
    margin: 0;
    text-align: center; }
  /* line 662, assets/scss/style.scss */
  .sqft-banner h1 {
    font-size: 3rem;
    letter-spacing: unset; }
    @media (min-width: 1024px) {
      /* line 662, assets/scss/style.scss */
      .sqft-banner h1 {
        font-size: 10rem; } }
  /* line 670, assets/scss/style.scss */
  .sqft-banner h2 {
    font-size: 2rem; }
    @media (min-width: 1024px) {
      /* line 670, assets/scss/style.scss */
      .sqft-banner h2 {
        font-size: 6rem; } }
  /* line 678, assets/scss/style.scss */
  .sqft-banner h3 {
    font-size: 2rem;
    margin: 1rem auto;
    background: #000;
    color: #fff;
    text-shadow: none;
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 400; }

/* line 695, assets/scss/style.scss */
.single-content__wrapper .buttonLink {
  font-size: 2rem; }

/* line 701, assets/scss/style.scss */
.redirect__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  width: 95%;
  text-align: center;
  margin: 3rem auto; }
  @media (min-width: 1024px) {
    /* line 701, assets/scss/style.scss */
    .redirect__content {
      width: 50%; } }
  /* line 715, assets/scss/style.scss */
  .redirect__content p.has-small-font-size {
    font-size: 1em; }
