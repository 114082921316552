@import "reset";
@import "mixins";
@import "vars";
@import "layout";
@import "type";
@import "components/header";

* {
    box-sizing: border-box;
}

#mobileTest {
    display: none;
}
@media (min-width: 801px) {
    #mobileTest {
        display: inherit;
    }
}
body {
    background: #ffffff;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
}

.clear {
    float: none;
    clear: both;
    display: block;
}

h1 {
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
}
h2 {
    color: #e20011;
    margin: 0.5em 0 0;
}

p {
    font-size: 2rem;
    line-height: 1.5em;
    margin: 0.5em 0 0;
}
a {
    color: #e20011;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
div.buttons {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
    position: relative;
    grid-gap: 4rem;

    @include breakpoint(laptop) {
        grid-template-columns: 1fr 1fr;
        max-width: 1080px;
    }
}
a.buttonLink {
    font-size: 1em;
    color: #fff !important;
    text-shadow: none;
    background: #e20011;
    width: 100%;
    padding: 0.25em;
    font-weight: 600;
    border-radius: 10px;
    @include card(1);
    transition: all 300ms;
    margin: 0.5em 0;
    display: block;
    text-align: center;

    &.shoolu {
        background: $shoolu;
        &:hover {
            background: darken($shoolu, 5);
        }
    }
}
a.buttonLink:hover {
    text-decoration: none;
    background: #ef5350;
    transform: scale(1.01);
    @include card(2);
    transition: all 100ms;
}
a.buttonLink:active {
    text-decoration: none;
    background: #d32f2f;
    transform: scale(0.999);
    box-shadow: none;
}

section .buttonLink {
    font-size: 1em;
}

.error {
    width: 80%;
    margin: 1em auto;
    background: #ff9800;
    color: #fff;
    padding: 0.5em;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    text-align: center;
}

.error:before {
    content: "\f071";
    font-family: fontAwesome;
    margin-right: 0.5em;
    font-weight: initial;
    font-size: 2rem;
    vertical-align: middle;
}
.success {
    width: 80%;
    margin: 1em auto;
    background: #0fb681;
    color: #fff;
    padding: 0.5em;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    text-align: center;
}

.success:before {
    content: "\f00c";
    font-family: fontAwesome;
    margin-right: 0.5em;
    font-weight: initial;
    font-size: 2rem;
    vertical-align: middle;
}

/*---OR Separator---*/

hr.or {
    width: 100%;
    position: relative;
    border: none;
    font-size: 1.5rem;
    background: none;
    &:after {
        position: absolute;

        content: "Or";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2em !important;
        height: 2em !important;
        border-radius: 2em;
        background: #000;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.buttons .or {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 4;
    height: 2em;
    margin: 0;
}

/*---BANNER---*/

.banner {
    width: 100%;
    background: url("assets/banner.jpg") center center fixed;
    background-size: cover;
    display: flex;
    padding: 2rem;
    align-items: center;
    text-transform: uppercase;

    justify-content: center;
    height: 75vh;
    text-align: center;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;
    flex-direction: column;

    &.small {
        height: 40vh;
        box-shadow: inset 0 0 20px #000;
    }

    .logo-img {
        width: 2em;
        height: 2em;
        object-fit: contain;
        vertical-align: middle;
        background: #fff;
        padding: 0.25rem;
        border-radius: $border-radius;
        @include breakpoint(laptop) {
            margin-right: 1rem;
        }
    }
    .buttonLink {
        font-size: 2rem;
    }

    h1,
    h2 {
        margin: 0 auto;
        border-radius: $border-radius;
        color: #000;
        @include card(2);
        line-height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1 {
        z-index: 2;
        width: 100%;
        font-size: 3rem;

        background: $tan;
        color: #fff;
        padding: 1rem 2rem;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
        @include breakpoint(laptop) {
            font-size: 6rem;
        }
    }

    h2 {
        background: #fff;
        border-radius: 0 0 $border-radius $border-radius;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
        font-size: 1rem;
        width: 80%;
        @include breakpoint(laptop) {
            font-size: unset;
            width: auto;
        }

    }
}

/*--SLIDER---*/
.slider button {
    position: absolute;
    z-index: 9999;
    height: 100%;
    top: 0;
    width: 4vw;
    background: none;
    border: none;
    color: #fff;
    font-size: 3em;
    opacity: 0.6;
    transition: all 300ms;
}
.slider button:hover {
    opacity: 1;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.slider button:focus {
    outline: none;
}

.slick-prev {
    left: -4vw;
}
.slick-next {
    right: -4vw;
}

.slick-list {
    padding: 4px !important;
}

/*---CONTENT---*/

section {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 1rem;

    @include breakpoint(laptop) {
        padding: 5vh 10vw;
    }
}
iframe#map {
    border: 15px solid #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
    max-width: 100%;
    margin: 0 auto;
    height: 50vh;
    pointer-events: none;
}
.maps {
    width: 100%;
}
p img {
    border: 15px solid #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
    max-width: 40%;
}
img.poster {
    max-width: 50vw;
    width: 100%;
    border: none;
    box-shadow: none;
    margin-top: -15vh;
}
img.left {
    float: left;
    margin-right: 1vw;
}
img.right {
    float: right;
    margin-left: 1vw;
}

.dark {
    background: #d1bb84;
    box-shadow: inset 0 0 15px -2.5px #000;
}

.headline {
    font-size: 3rem;
    text-align: center;
    line-height: 2em;
    margin: 5vh 0;
    font-weight: 100;
}

.promo {
    width: 50%;
    margin: 0 auto;
    background: #e20011;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 2rem;
    padding: 1.5em;
    font-weight: 600;
    box-shadow: 0px 0px 10px -2.5px #000;
}

.finePrint {
    font-size: 1rem;
    font-weight: 100;
}

.featured {
    padding: 4rem 1rem;
    h2 {
        font-size: 4rem;
        text-align: center;
        color: #000;
        margin: 1rem auto;
    }
    a {
        font-size: 2rem;
        text-align: right;
        display: block;
        max-width: 80%;
        margin: 0 auto;

    }
    .slider {
        max-width: 100%;
        margin: 1rem auto;
        @include breakpoint(laptop) {
            max-width: 80%;
        }
    }
}
.grid {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.item {

    flex: 1 1 auto;
    width: 23%;
    min-height: 20vw;
    font-size: 2rem;
    margin-bottom: 4vh;
    background: #fff;
    border-radius: 8px;
    @include card(1);

    display: flex;

    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    transition: all 300ms;
    padding: 1rem;

    @include breakpoint(laptop) {
        padding: 4vh;
        
    }
}

.slider .item {
    margin-bottom: 0;
    margin: 0 0.25em;
    @include breakpoint(laptop) {
        margin: 0 1em;
    }
}
.item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-transition: all 300ms;
    transition: all 300ms;
}
.brand {
    width: 15%;
    padding: 10px;
    border-radius: 20%;
}
.button {
    min-height: 20vh;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.button:hover {
    background: #e20011;
    color: #fff;
}
.button:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}
.button .fa {
    font-size: 8rem;
}
.item a {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
}

.item .content a{
    display: inline;
    font-weight: 900;
}

/*--SURVEY--*/
.surveyForm input {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 0.5em;
}
.surveyForm input[type="text"],
.surveyForm input[type="email"] {
    border: none;
    border-bottom: 2px solid #000;
}
.surveyForm input[type="submit"] {
    background: #e20011;
    color: #fff;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.23);
    cursor: pointer;
    padding: 0.5em 0;
}
.surveyForm label {
    font-size: 2rem;
}
.surveyForm input[type="checkbox"] {
    width: auto;
    display: inline-block;
}
.rating {
    margin: 0;
    font-size: 2rem;
    padding: 0;
    float: left;
}
.rating li {
    display: inline-block;
}
.rating input[type="radio"] {
    display: none;
}
label.star {
    float: right;
    padding: 10px;
    font-size: 36px;
    color: #444;
    transition: all 0.2s;
}

input.star:checked ~ label.star:before {
    content: "\f005";
    color: #fd4;
    transition: all 0.25s;
}

input.star-5:checked ~ label.star:before {
    color: #fe7;
    text-shadow: 0 2px 3px #f9a825;
}

input.star-1:checked ~ label.star:before {
    color: #f62;
}

label.star:hover {
    transform: rotate(-15deg) scale(1.3);
}

label.star:before {
    content: "\f006";
    font-family: FontAwesome;
}

@import "components/footer";




@media screen and (max-width: 850px) {
    html {
        font-size: 13px;
    }
    section {
        padding: 5vh 1rem;
    }
    .headline {
        margin-top: 0;
        line-height: 1.5em;
        font-size: 2rem;
    }
    .promo {
        width: 100%;
    }
    .item {
        width: 40%;
    }
    .brand {
        width: 45%;
    }
    .menuRight,
    .menuLeft {
        display: none;
    }
    .logo {
        width: 60%;
    }
    .buttonLink {
        width: 100% !important;
    }
    .card {
        width: 70%;
    }
    .hoursStuff {
        flex-direction: column;
        align-items: center;
    }


    p img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    img.left,
    img.right {
        float: none;
        margin: 0;
    }
}

.sqft-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0.5rem;

    @include breakpoint(laptop) {
        padding: 4rem 2rem;
    }

    h1,
    h2,
    h3 {
        color: #000;
        font-weight: 900;
        margin: 0;
        text-align: center;
    }

    h1 {
        font-size: 3rem;
        letter-spacing: unset;
        @include breakpoint(laptop) {
            font-size: 10rem;
        }
    }

    h2 {
        font-size: 2rem;

        @include breakpoint(laptop) {
            font-size: 6rem;
        }
    }

    h3 {
        font-size: 2rem;
        margin: 1rem auto;
        background: #000;
        color: #fff;
        text-shadow: none;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 400;
    }
}

.single-content__wrapper {
    .buttonLink {
        font-size: 2rem;
    }
}

.redirect {
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
        @include card(2);
        width: 95%;
        text-align: center;
        margin: 3rem auto;
        @include breakpoint(laptop) {
            width: 50%;
        }

        p.has-small-font-size{
            font-size: 1em;
        }
    }
}
