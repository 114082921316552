/*---FOOTER---*/

.hoursStuff {
    width: 100%;
    min-height: 30vh;
    background: #eee;
    background: url("assets/swirly-thing.png") center bottom / cover;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    margin-top: 5vh;
    padding: 1rem;
    .card {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 1rem;
        width: 100%;
        background: #fff;
        @include card(1);
        text-align: center;
        &.shoolu{
            img{
                margin: 1rem auto;
            }

            @include breakpoint(tabletdown) {
                order: -1;
            }
        }
    }
     .buttonLink {
        font-size: 1.5em;
        width: 100%;
        margin: 0.5em 0;
    }

    
}
.hoursStuff p {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}

footer {
    padding: 3vh 10vw;
    background: #665a42;
    color: #fff;
    @include card(-1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 1rem;

    .fa {
        margin-left: 0.5em;
    }
    a {
        color: inherit;
        text-decoration: none;
        -webkit-transition: color 200ms;
        transition: color 200ms;
    }
    a:hover {
        color: rgba(0, 0, 0, 0.6);
    }
}
