@mixin flex($direction) {
    // write the css here
    display: flex;
    @if $direction=="column" {
        flex-direction: column;
    }
    @else {
        flex-direction: row;
    }
    @content;
}

@mixin breakpoint($point) {
    @if $point==desktop {
        @media (min-width: 1440px) {
            @content ;
        }
    }
    @else if $point==laptop {
        @media (min-width: 1024px) {
            @content ;
        }
    }
    @else if $point==tablet {
        @media (min-width: 768px) {
            @content ;
        }
    }
    @else if $point==tabletdown {
        @media (max-width: 768px) {
            @content ;
        }
    }
    @else if $point==mobileonly {
        @media (max-width: 600) {
            @content ;
        }
    }
    @else if $point==portrait {
        @media (orientation: portrait) {
            @content ;
        }
    }
}

.mobile-only{
    display: none;
    @include breakpoint(tabletdown){
        display:flex;
    }
}

.tablet-only{
    display: none;
    @include breakpoint(tabletdown){
        display:flex;
    }
    @include breakpoint(tablet){
        display:flex;
    }
    @include breakpoint(laptop){
        display: none;
    }
}
.desktop-only{
    display: none;
    @include breakpoint(laptop){
        display:flex;
    }
}


// PX to REM - Usage: font-size: rem(37px);
@function rem($px,
$base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

// Transitions - Usage: @include transition(all .2s ease);
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}


@function top-shadow($depth) {
    $primary-offset: nth(1 3 10 14 19, $depth) * 1px;
    $blur: nth(2 3 10 14 19, $depth) * 4px;
    $color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

    @return 0 $primary-offset $blur $color;
}

// Computes a bottom-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}
@function bottom-shadow($depth) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

    @return 0 $primary-offset $blur $color;
}

@mixin card($depth) {
    @if $depth < 1 {
        box-shadow: inset bottom-shadow(abs($depth)), inset top-shadow(abs($depth));
    }
    @else if $depth>5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    }
    @else {
        box-shadow: bottom-shadow($depth), top-shadow($depth);
    }
}
