/*header*/

header {
    width: 100%;
    background: $tan;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    height: 10vh;
    filter: drop-shadow(0px 0px 10px #000);
    font-weight: 400;
    z-index: 9999;
}

.logo {
    height: 15vh;
    border-radius: 0 0 90% 90%;

    display: flex;

    align-items: center;

    justify-content: center;
    background: $tan;
    -ms-flex-item-align: start;
    align-self: flex-start;
    width: 35%;
}
.logo a {
    width: 70%;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.headerPromo {
    width: 100%;
    background: #e20011;
    color: #fff;
    padding: 0.5em 0;
    text-align: center;
    font-size: 1.5rem;
    display: block;
}
.headerPromo a {
    color: #fff;
    border: 2px solid;
    padding: 0.1rem 0.5rem;
    transition: all 300ms;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: 1rem;
    font-weight: 900;
}
.headerPromo a:hover {
    background: #fff;
    color: #e20011;
    text-decoration: none;
    border: 2px solid #fff;
}

.menuRight,
.menuLeft {
    width: 20%;
    font-size: 1.25rem;
}
nav.mobile {
    display: none;
}
.mobileNavButton {
    display: none;
}
.mobilespacer {
    display: none;
}
.mobileNavExit {
    display: none;
}
.menuRight ul,
.menuLeft ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.menuRight li,
.menuLeft li {
    display: inline-block;
}

nav li a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}


@include breakpoint(tabletdown) {
    nav.mobile {
        position: fixed;
        top: 0;
        left: -100vw;
        z-index: 999;
        transition: left 200ms;
        display:block;
    }
    .mobileNavExit{
        display: none;
    }
    #nav:target {
        left: 0;
        .mobileNavExit {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 998;
            display: flex;
        }
    }
    .mobileNavButton {
        display: block;
        font-size: 2rem;
    }
    .mobilespacer {
        display: block;
    }
    .mobile ul {
        min-width: 70vw;
        background: #333;
        margin: 0;
        padding: 0;
        height: 100vh;
        box-shadow: 0 2px 3px #000;
        z-index: 999;
        position: relative;
    }
    .mobile li {
        display: block;
        color: #fff;
        padding: 1.5rem 1rem;
        font-size: 3rem;
        border-bottom: 1px solid #fff;
        margin-top: 0;

    }
    .mobile li:hover {
        background: #777;

    }
    .mobile a {
        text-decoration: none;
        margin-top: 0;
    }
}